import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import TimelineElement from '../components/TimelineElement';
import { VerticalTimeline }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export const experienceQuery = graphql`
  query experienceQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date_from] }
      filter: { frontmatter: { type: { eq: "experience" } } }
		) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date_from
            date_to
            present
			      title
			      company
            description
          }
        }
      }
    }
  }
`

const ExperienceList = ({ data }) => {
  const list = data.allMarkdownRemark.edges
    .map(experience =>
      <TimelineElement key={experience.node?.id} element={experience.node}/>
    )

    if (list.length === 0){
      return <p>There are no experience to show.</p>
    }

  return (
    <VerticalTimeline
    className="custom-vertical-timeline"
    >
      { list }
    </VerticalTimeline>
  )
}

const Experience = ({ data }) => (
      <Layout fullMenu>
        <article id="main">
          <header>
            <h2>Experience</h2>
          </header>
          <section className="wrapper style5">
            <div className="inner">
              <ExperienceList data={data} />
            </div>
          </section>
        </article>
      </Layout>
)


export default Experience;
