import React from 'react';
import {VerticalTimelineElement }  from 'react-vertical-timeline-component';
import { parseDate } from '../utils';

const TimelineElement = ({ element }) => {
  const experience = element.frontmatter;
  const fromDate = parseDate({ stringDate: experience?.date_from })
  const toDate = experience?.present ? "Currently" : parseDate({ stringDate: experience?.date_to })
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{
        border: "1px solid rgba(0,0,0,0.08)",
        boxShadow: "0 5px 10px rgba(0,0,0,0.05)",
        background: "#fff"
      }}
      contentArrowStyle={{ borderRight: '7px solid  #2e3942' }}
      date={`${fromDate} - ${toDate}`}
      iconStyle={{ background: "#2e3942", border: "1px solid #2e3942" }}
    >
      <h3 className="vertical-timeline-element-title">{experience?.title}</h3>
      <h5 className="vertical-timeline-element-subtitle">{experience?.company}</h5>
      <p>{experience?.description}</p>
    </VerticalTimelineElement>
  );
}
export default TimelineElement;
